import React, { useRef } from "react"
import { ErrorMessage } from "formik"
import PropTypes from "prop-types"
import ClassNames from "classnames"
import styles from "./input-control.module.sass"
import Typography from "../../UIKit/base/Typography"
import { spawn } from "redux-saga/effects"

/**
 *
 *  The  params with Formik https://formik.org/docs/api/
 */

const InputControl = ({
	className,
	label,
	variant: Component = "input",
	type = "text",
	children = null,
	message = false,
	error = "",
	labelClassName = "",
	placeholder = "",
	refEffect = () => {},
	fontVariant = "medium",
	required,
	...props
}) => {
	const htmlFor = `${type}-${Math.random()}`
	const wrapperRef = useRef(null)
	const attrs = props.field ? { ...props.field } : { ...props }
	const erorStyle = props.form
		? props.form.errors &&
		  props.form.errors[attrs.name] &&
		  props.form.touched &&
		  props.form.touched[attrs.name]
		: error
	const value = props.field
		? props.field.value
			? props.field.value
			: ""
		: props.value
	return (
		<div className={ClassNames(styles.wrapper, className)} ref={wrapperRef}>
			<Typography
				component="label"
				fontVariant={fontVariant}
				className={ClassNames(styles.label, labelClassName)}
				htmlFor={htmlFor}
			>
				{label}
				{required?<span>*</span>:null}
			</Typography>
			{Component === "textarea" ? (
				<div className={styles.textarea}>
					<Typography
						fontVariant={fontVariant}
						component={Component}
						id={htmlFor}
						type={type}
						{...attrs}
						value={value}
						className={erorStyle ? styles.error : null}
						placeholder={placeholder}
					/>
				</div>
			) : (
				<Typography
					fontVariant={fontVariant}
					component={Component}
					id={htmlFor}
					type={type}
					{...attrs}
					value={value}
					className={erorStyle ? styles.error : null}
					placeholder={placeholder}
					autoComplete="one-time-code"
				/>
			)}

			{children}

			<p className={styles.message}>
				{attrs.name && message && <ErrorMessage name={attrs.name} />}
			</p>
		</div>
	)
}

InputControl.propTypes = {
	type: PropTypes.string,
	children: PropTypes.node,
	message: PropTypes.bool,
	error: PropTypes.string,
	labelClassName: PropTypes.string,
	placeholder: PropTypes.string,
	refEffect: PropTypes.func,
	variant: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.object,
	]),
}

export default InputControl
